import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Spinner, Alert } from 'react-bootstrap';
import Aos from 'aos';
import logo from './assets/Recurso 3svg.svg';
import firebase from './config/firebase.config';

import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = styled.div`
  background-color: #101820;
  width: 100vw;
  min-height: 100vh;
  color: #FFC629;
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif;

  img {
    width: 100%;
  }

  .redes {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    a {
      color: #FFC629;
      text-decoration: none;
    }
  }

`;

const Input = styled.input`
  background-color: transparent;
  border: 1px solid #FFC629;
  color: #FFC629;
  outline: none;
  padding: 5px 15px;
  width: 100%;
  margin: 5px;
  &::placeholder {
    color: #fff;
  }
`;

const Button = styled.button`
  background-color: #FFC629;
  color: #fff;
  padding: 5px 15px;
  border: none;
  margin: 5px;
  font-weight: bold;
  width: 100%;
  transition: all .5s ease;

  &:hover {
    color: #FFC629;
    border: #FFC629 1px solid;
    background: transparent;
  }
`;

const App = () => {

  useEffect(() => {
    Aos.init({
      duration: 1000,
      disable: 'mobile'
      
    });
  }, [])

  const [email, setEmail] = useState("");
  const [send, setSend] = useState(false);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  const enter = (e) => {
    if (e.key === "Enter") {
      validEmail()
    }
  }

  const validEmail = async () => {
    setSend(true);
    console.log(email);
    if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ) {
      try {
        await firebase.firestore().collection("correos").doc(email).set({
          email,
          date: new Date()
        })
        setSend(false);
        setShow(true);
        setEmail("");
        setTimeout(() => {
          setShow(false);
        }, 5000)
      } catch (error) {
        setSend(false);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000)
      }
      
    } else {
      setSend(false);
      setEmail("");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000)
    }
    
  }

  return (
    <Home>
      <Row style={{
        minHeight: "100vh",
        padding: "40px"
      }}>
        <Col
          md={4}
          style={{
            padding: 15,
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img data-aos="fade-up" src={logo} alt='logo revolt labs' />
        </Col>
        <Col
          md={8}
          style={{
            padding: 15,
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column"
          }}
        >
          <h1 data-aos="fade-down" style={{
            fontWeight: "bold"
          }}>
            Revolt Labs
          </h1>
          <h5 data-aos="fade-down">
            Próximamente
          </h5>
          <p data-aos="fade-down" style={{
            textAlign: "center"
          }}>
            Una empresa que busca revolucionar la industria del <br/> Desarrollo de software.
          </p>
          <label data-aos="fade-up" htmlFor="email" style={{textAlign: "left", fontWeight: "bold", marginTop: 25}}>Contáctanos:</label>
          {
            show &&
            <Alert style={{
              width: "80%"
            }} variant="success">
              Pronto nos pondremos en contacto.
            </Alert>
          }
          {
            showError &&
            <Alert style={{
              width: "80%"
            }} variant="danger">
              Coloca un correo valido.
            </Alert>
          }
          <Row data-aos="fade-up">
            <Col
              md={8}
            >
              <Input value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Correo" id="email" onKeyDown={enter} />
            </Col>
            <Col
              md={4}
            >
              <Button
                onClick={validEmail}
              >
                {
                  send ?

                    "Enviando ..."

                  : "Enviar"
                }
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="redes" data-aos="fade-left">
        <a href="https://www.tiktok.com/@revoltlabs?is_copy_url=1&is_from_webapp=v1&lang=es" rel="noreferrer" target="_blank">
          <i className="fab fa-tiktok"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100075247080847" rel="noreferrer" target="_blank">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/revolt.labs/" rel="noreferrer" target="_blank">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://twitter.com/revolt_labs" rel="noreferrer" target="_blank">
          <i className="fab fa-twitter"></i>
        </a>
      </div>
    </Home>
  );

}

export default App;
