import firebase from 'firebase/compat';

const config = {
    apiKey: "AIzaSyBvtQ9o_gVwhTkurcrGPNRJnBrI2yZpflw",
    authDomain: "revolt-4094f.firebaseapp.com",
    projectId: "revolt-4094f",
    storageBucket: "revolt-4094f.appspot.com",
    messagingSenderId: "721374141238",
    appId: "1:721374141238:web:8fdc5be22eb106b4c7b197",
    measurementId: "G-NXKK1M35DZ"
}

export default firebase.initializeApp(config);
